/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    br: "br",
    h3: "h3",
    ol: "ol",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(_components.p, null, "To add event notifications to your integration, use our API to create an event subscription and handle the notification that we send by webhook when an event occurs."), "\n", React.createElement(Admonition, {
    type: "warning"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Important:"), " To receive notifications, your server must be able to handle POST requests.")), "\n", React.createElement(_components.p, null, "To create an event subscription, send the following information in your request:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Event type"), " - The event that you want to subscribe to. You can subsribe to more than one event within the same request."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "URI"), " - The endpoint that we send notifications to. The endpoint must be publicly available."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Secret"), " - A secret that we return in the header of webhook requests to verify that it is a genuine request."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Event status"), " - The status of the event subscription."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Email address"), " - An email address that we contact if we can't communicate with the endpoint that you provided."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " For a complete list of events that you can subscribe to, go to ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/knowledge/events/events-list"
  }, "Events list"), ".")), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " Create an event subscription", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " Send a 200 response code", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 3."), " Handle the notification content"), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1. Create an event subscription"), "\n", React.createElement(_components.p, null, "To create an event subscription, send a POST request to our event subscriptions endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/event-subscriptions"
  }, "https://api.uat.payroc.com/v1/event-subscriptions"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/event-subscriptions"
  }, "https://api.payroc.com/v1/event-subscriptions")), "\n", React.createElement(_components.h3, null, "Request parameters"), "\n", React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(RequestParameters, {
    operationId: "createEventSubscription"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "createEventSubscription"
  })), "\n", React.createElement(_components.h3, null, "Response fields"), "\n", React.createElement(_components.p, null, "If your request is successful, we send you the ID of the subscription. You can use the ID to update, retrieve, or delete the event subscription."), "\n", React.createElement(_components.p, null, "The response contains the following fields:"), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(ResponseBody, {
    operationId: "createEventSubscription"
  })), "\n", React.createElement(_components.h3, null, "Example response"), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createEventSubscription"
  })), "\n", React.createElement(_components.h2, null, "Step 2. Send a 200 response code"), "\n", React.createElement(_components.p, null, "We send notifications by webhook request to the URI endpoint that you provided when you created the subscription."), "\n", React.createElement(_components.p, null, "You must return a 200 status code when you receive the request."), "\n", React.createElement(_components.p, null, "If we do not receive a 200 response, we retry the request five times. After the fifth attempt, we send an email to the support email address that you provided when you created the subscription."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " We send an email only when a single instance of an event notification fails to deliver. If the event occurs again, we send another notification.")), "\n", React.createElement(_components.h2, null, "Step 3. Handle the notification content"), "\n", React.createElement(_components.p, null, "Each notification follows the ", React.createElement(_components.a, {
    href: "https://cloudevents.io/"
  }, "CloudEvents"), " standard, and we use the data object to communicate information about the event that occurred, for example:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"specversion\": \"1.0\",\n  \"type\": \"processingAccount.status.changed\",\n  \"version\": \"1.0\"\n  \"source\": \"payroc\",\n  \"id\": \"123e4567-e89b-12d3-a456-426614174000\",\n  \"time\": \"2024-05-12T12:34:56.789Z\",\n  \"datacontenttype\": \"application/json\",\n  \"data\": {\n    \"processingAccountId\": \"12345678\",\n    \"status\": \"entered\"\n  }\n}\n")), "\n", React.createElement(_components.p, null, "You can view our event notifications on the ", React.createElement(_components.a, {
    href: "/knowledge/events/events-list"
  }, "Events"), " page."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
